<template>
    <!-- 退房 -->
    <section class="check-out padding_10_15">
        <div class="top-bar bg-white">
          <div style="display: flex;align-items: center">申请时间</div>
            <el-date-picker v-model="startAndEndDate" type="daterange" value-format="yyyy-MM-dd" format="yyyy-MM-dd"
                            style="margin: 0 10px;" size="small" range-separator="至" start-placeholder="开始日期"
                            end-placeholder="结束日期"/>
          <div style="margin-left: 10px">
            <el-input
                placeholder="请输入房间/租户"
                v-model="formSearch.apartmentName"
                size="small"
                clearable>
            </el-input>
          </div>
          <div style="margin-left: 20px;margin-right: 20px">
            <el-select v-model="formSearch.dismissed" placeholder="请选择"  size="small">
              <el-option :label="item.label" v-for="item in roleTypeDic" :value="item.value" :key="item.value"></el-option>
<!--              <el-option label="待确认" :value="item.value"></el-option>-->
<!--                  <el-option label="已确认" :value="1"></el-option>-->
            </el-select>
          </div>
            <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
            <el-button @click="handleReset" size="small">重置</el-button>
        </div>

        <r-e-table class="bg-white" ref="tableRef" :dataRequest="getHomeAlert" :columns="tableColumn" :query="formSearch"
                   tooltip-effect="light" :cell-style="cellStyle" :height="800">
            <template slot="empty">
                <el-empty/>
            </template>
            <el-table-column slot="toolbar" label="操作" width="200">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" v-if="row.dismissed===false" @click="handle(row)">确认</span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>
    </section>
</template>

<script>
    import {tableCheckOutColumn} from "@/views/home/data";
    import {getHomeAlert} from "@/api/home";
    import {dismiss} from "@/api/log";
    import {MessageSuccess} from "@custom/message";
    import {timeFormat} from "@custom/index";
    export default {
        name: "check-out",
        data() {
            return {
                tableColumn: tableCheckOutColumn(this),
                startAndEndDate: [],
                roleTypeDic:[{value:'-1',label:'全部'},{value:'0',label:'待确认'},{value:'1',label:'已确认'}],
                formSearch:{
                  apartmentName: '',
                  dismissed: '-1',
                  type: "退房"
                }
            };
        },
        mounted() {

        },
        methods: {
          // 表格颜色事件
          cellStyle({row, column, rowIndex, columnIndex}) {
            let {dismissed} = row;
            if (!dismissed  && columnIndex === 5) return {color: '#FF3718'};
            //  字体颜色
          },
            //查询退房信息
            getHomeAlert(params) {
                params.startDate = this.startAndEndDate[0]; // 将数组的第一个元素赋值给 startDate
                params.endDate = this.startAndEndDate[1]; // 将数组的第二个元素赋值给 endDate
                params = {...params,...this.formSearch}
                return getHomeAlert(params);
            },
            //查询
            handleSearch() {
                this.$refs["tableRef"].getTableData(true);
            },
            //重置
            handleReset() {
                this.formSearch = {
                  apartmentName: '',
                  dismissed: '-1',
                  type: "退房"
                };
                this.startAndEndDate = [];
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            },
            //已读退房信息
            handle(data){
                let {url, uuid} = data;
                this.$confirm('此操作将会确认已读该条消息, 是否继续', '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning',
                }).then(() => {
                    dismiss(uuid).then(res => {
                        MessageSuccess('操作成功!');
                        //判断是否携带跳转路由
                        if (url) {
                            //拆分路由和参数
                            let urlList = url !== "" ? url.split("+-+").filter(item => item !== "") : [];
                            let paramsData = {};
                            let propList = urlList[1].split(";").filter(item => item !== "") !== 0 ? urlList[1].split(";").filter(item => item !== "") : [];
                            //判断时候有参数
                            if (propList.length !== 0) {
                                //组装参数
                                for (let item of propList) {
                                    let propArr = item.split(":").filter(item => item !== "") !== 0 ? item.split(":").filter(item => item !== "") : [];
                                    if (propArr.length !== 0) paramsData[propArr[0]] = propArr[1];
                                }
                            }
                            //跳转对应界面
                            this.$router.push({path: urlList[0], query: {data: paramsData}});
                        }
                    })
                }).catch(() => {});
            }
        },
    //     watch: {
    //     // startAndEndDate(value) {
    //     //     if (value) {
    //     //         let [startDate, endDate] = value;
    //     //         this.formSearch.startDate = timeFormat(startDate);
    //     //         this.formSearch.endDate = timeFormat(endDate);
    //     //     } else {
    //     //         this.formSearch.startDate = null;
    //     //         this.formSearch.endDate = null;
    //     //     }
    //     // },
    // },
        };
</script>

<style lang="scss" scoped>
    .check-out {
        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(25px);
            padding: VH(15px) VW(15px);
            justify-content: flex-start;
        }

        /deep/ .r-e-table {
            .el-table .cell {
                white-space: nowrap;
                text-align: center;
            }
        }
    }
</style>
