import request from '@http';

// 2022/05/20 王江毅 日志列表
export function getLogs(params) {
    return request({
        method: 'GET',
        url: `/api/audit`,
        params
    })
}

// 2022/05/20 王江毅 消息列表
export function getMsgs(params) {
    return request({
        method: 'GET',
        url: `/api/alerts/type`,
        params
    })
}

// 2022/06/14 王江毅 消息已读处理
export function dismiss(uuid) {
    return request({
        method: 'put',
        url: `/api/alerts/outstanding/${uuid}/dismiss`
    })
}

// 2023/02/21 王江毅 操作日志列表
export function getOperationLogList(params) {
    return request({
        method: 'GET',
        url: `/api/system/log/list`,
        params
    })
}

// 2023-12-8-陈守亮-短信日志
export function shortmessageLogList(params) {
    return request({
        method: 'GET',
        url: `/api/system/log/sms/list`,
        params
    })
}