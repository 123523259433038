/**
 * Download according to the background interface file stream
 * @param {*} data 数据流
 * @param {*} filename 文件名称
 * @param {*} mime  类型
 * @param {*} bom 数据
 */
export function downloadByData({data, filename, mime, bom}) {
    const blobData = typeof bom !== 'undefined' ? [bom, data] : [data];
    const blob = new Blob(blobData, {type: mime || 'application/octet-stream'});
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        window.navigator.msSaveBlob(blob, filename);
    } else {
        const blobURL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', filename);
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
        }
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
    }
}

//2023/05/16 王江毅 base64转Blob 对象 dataURI:base64字符串带前缀
export function dataURItoBlob(dataURI) {
    // 将 base64 字符串中的逗号分隔数据替换为一个空格
    const byteString = atob(dataURI.split(',')[1]);

    // 分割字符串并提取出 mime 类型和编码格式
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // 将字符串转换成 Uint8Array
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // 返回 Blob 对象
    return new Blob([ab], {type: mimeString});
}

// 2023/05/16 王江毅  Blob对象 转换为 base64 编码的字符串
export function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = () => {
            reject('Error occurred while converting blob to base64');
        };
    });
}

//2023/09/27 王江毅 base64转Blob 对象 输出PDF类型Blob
export function base64ToBlobByPDF(code) {
    //Base64一行不能超过76字符，超过则添加回车换行符。因此需要把base64字段中的换行符，回车符给去掉，有时候因为存在需要把加号空格之类的换回来，取决于base64存取时的规则。
    code = code.replace(/[\n\r]/g, "");
    let raw = window.atob(code);
    let rawLength = raw.length;
    //转换成pdf.js能直接解析的Uint8Array类型
    let uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], {type: "application/pdf"}); //转成pdf类型
}