<template>
    <section class="coming-due">
        <div class="title" style="display: flex;justify-content: space-between;padding-right: 28px">即将到期合同
         <div>
           <!-- <el-select   v-model="formSearch.district" size="small" clearable filterable allow-create
                       default-first-option placeholder="请选择区域" style="width: 120px;">
             <el-option v-for="(item,index) in options" :key="index" :label="item"
                        :value="item"/>
           </el-select> -->
           <el-select  v-model="formSearch.apartmentCode" size="small" clearable filterable allow-create
                      default-first-option placeholder="请选择住房类型" style="width: 140px;">
             <el-option label="全部" :value="0"/>
             <el-option label="保障性租赁住房" :value="600210"/>
             <el-option label="长租公寓" :value="600220"/>
           </el-select>
           <el-select size="small" v-model="formSearch.apartmentCodeson" placeholder="请选择"
                      style="width: 120px;">
             <el-option label="全部" :value="0"/>
             <el-option v-for="(item,index) in houseTypes" :key="index" :label="item.codeName"
                        :value="item.code"/>
           </el-select>
<!--           <el-select @change="changeSaixuan" v-model="formSearch.leasorType" size="small" clearable filterable allow-create-->
<!--                      default-first-option placeholder="请选择租户类型" style="width: 140px;">-->
<!--             <el-option label="全部" :value="0"/>-->
<!--             <el-option  label="个人" value="个人"/>-->
<!--             <el-option  label="法人" value="法人"/>-->
<!--           </el-select>-->
           <el-button style="margin-left: 12px;" size="mini" type="primary" @click="changeSaixuan">查 询</el-button>
         </div>
        </div>
        <div style="margin: 10px 0;padding-bottom: 50px;background-color: white;">
            <r-e-table class="bg-white" ref="orderTableRef" :data="getTableDatalist" :columns="tableColumn"
                       :query="formSearch" :height="425" :showPagination="false">
                <template slot="empty">
                    <el-empty/>
                </template>
            </r-e-table>
          <el-pagination
              style="text-align: center"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage4"
              :page-sizes="[10, 20, 40, 80]"
              :page-size="100"
              layout="total, sizes, prev, pager, next,jumper"
              :total="page.totalNum">
          </el-pagination>
        </div>
    </section>
</template>

<script>
import {comingDue} from "@/views/home/data";
import {getHomeExpirationAPI} from "@/api/home"
import {getAddressList} from "@/api/community-management";

export default {
    name: "coming-due",
    data() {
        return {
          houseTypes:[],
            tableColumn: comingDue(this),
            formSearch: {
                keyword: null,
              apartmentCode:null,
              leasorType:null,
              apartmentCodeson:null,
              district:''
            },
          options:[],
          getTableDatalist:[],
          currentPage4: 1,
          page:{
            totalNum:'',
            page:'1',
            pageSize:'10',
            totalPage: '10'
          }
        };
    },
    components: {},
  async  mounted() {
     let res = await getAddressList();
     console.log(res.list)
     this.options = res.list;
     this.options.unshift('全部')
     console.log(this.options,'this.options')
  },
   created() {
    this.getTableData()

  },
  watch:{
    async 'formSearch.apartmentCode'(value) {
      if (value !== 0) {
        this.formSearch.apartmentCodeson = null;
        this.houseTypes = await this.$store.dispatch('app/getDictionaryByCodeActions', value);
      } else {
        this.formSearch.apartmentCodeson = null;
        this.houseTypes = [];
      }
    },
  },
    methods: {
      handleSizeChange(e){
        console.log(e)
        this.page.pageSize = e
        this.getTableData()
      },
      handleCurrentChange(e){
        console.log(e)
        this.page.page = e
        this.getTableData()
      },
        getTableData(params) {
          // console.log(getHomeExpirationAPI(params))
          //   return getHomeExpirationAPI(params);
          getHomeExpirationAPI({...this.formSearch,...this.page}).then(res => {
            console.log(res.list)
            this.page =res.page
            this.getTableDatalist = res.list
            this.getTableDatalist = this.getTableDatalist.sort((a, b) => {
              return   a.remainingDays -b.remainingDays
            });
          })
        },
      // 赛选小区
      async changeSaixuan(){
        console.log(this.formSearch)
        await this.getTableData(this.formSearch)
      },

    }
}
</script>

<style lang="scss" scoped>
.coming-due {
    width: 49%;

    .title {
        height: VH(55px);
        line-height: VH(55px);
        color: #666;
        padding-left: VW(10px);
        position: relative;

        &::before {
            width: 5px;
            height: 40%;
            background-color: #5C84FB;
            content: '';
            position: absolute;
            left: 0;
            top: 30%;
        }
    }
}
</style>