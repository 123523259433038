<template>
    <section class="retire-situation flex flex-warp justify-between">
        <!--        <div class="retire-item bg-white" v-for="item in retireOptions" :key="item.name">
                    <div :style="{color:item.color}" style="width: 100%;">
                        <div class="label" style="text-align: left;">{{ item.label }}</div>
                        <div class="label" style="padding-bottom: 0; text-align: right; font-size: 1.5rem;">{{ item.value }} <span>{{ item.unit }}</span></div>
                    </div>
                </div>-->
        <div style="width: 100%;height: 100%;background-color: white; ">
            <div style="position: relative;text-align: center;top:-1px;width: 100% !important; margin-top: 22px">
                <el-radio-group style="color: black;width: 100% !important;" v-model="reasonCode" @change="getHomeOverview">
                    <el-radio-button :label="-1">
                        <el-badge :value="all">
                            <div style="width: 100px;">
                                全部
                            </div>
                        </el-badge>
                    </el-radio-button>
                    <el-radio-button :label="1">
                        <el-badge :value="expire">
                          <div style="width: 100px;">
                            到期退租
                          </div>
                        </el-badge>
                    </el-radio-button>
                    <el-radio-button :label="2">
                        <el-badge :value="d1">
                          <div style="width: 100px;">
                            中途退租
                          </div>
                          </el-badge>
                    </el-radio-button>
                </el-radio-group>
            </div>
            <div style="padding: 5px 0;border-radius: 15px;overflow: hidden;" v-loading="loading">
                <div class="flex justify-between" style="font-weight: bold;font-size: 1.125rem;color:#80A0B1;">
                    <div class="flex align-center justify-center" style="height: 50px;width: 33%;">租户</div>
                    <div class="flex align-center justify-center" style="height: 50px;width: 33%;">小区</div>
                    <div class="flex align-center justify-center" style="height: 50px;width: 33%;">退租时间</div>
                </div>
                <div style="height: 35vh;overflow-y: auto;font-size: 1.125rem;color: #606266">
                    <div v-for="(item,index) in list" class="flex justify-between" :key="index">
                        <div class="flex align-center justify-center" style="height: 50px;width: 20%;margin-left: 7%;text-align: center">
                            {{ item.leasorName }}
                        </div>
                        <div class="flex align-center justify-center" style="height: 50px;width: 33.5%;margin-left: 22px;text-align: center">
                            {{ item.communityName }}
                        </div>
                        <div class="flex align-center justify-center" style="height: 50px;width: 31%;text-align: center">
                            {{ item.checkOutDate }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {retireOptions} from "@/views/home/data";
import {throwLease, getHomeOverviewAPI, getOverviewCountAPI} from "@/api/home";

export default {
    name: "retire-situation",
    data() {
        return {
            retireOptions,
            reasonCode: -1,
            list: [],
            loading: false,
            all: 0,
            d1: 0,
            expire: 0,
        };
    },
    props: {
        collectionData: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        //获取上月退租情况
        async throwLease(paramData) {
            //调用接口获取数据
            let res = await throwLease(paramData);
            //拆分数据
            let {info: {TZCount, TZPercent, DQCount, ZTCount}} = res;
            let {retireOptions} = this;
            // 循环判断标题根据标题赋值
            for (let item of retireOptions) {
                if (item.label === "退租数量") {
                    item.value = TZCount;
                }
                if (item.label === "退租率") {
                    item.value = TZPercent;
                }
                if (item.label === "到期退租") {
                    item.value = DQCount;
                }
                if (item.label === "中途退租") {
                    item.value = ZTCount;
                }
            }
            //赋值
            this.retireOptions = retireOptions;
        },
        getHomeOverview() {
            const that = this;
            const reasonCode = this.reasonCode;
            that.loading = true;
            getHomeOverviewAPI({reasonCode}).then(res => {
                const {list} = res;
                that.list = list;
              console.log(that.list)
                that.getOverviewCount();
            }).finally(() => that.loading = false);
        },
        getOverviewCount() {
            const that = this;
            getOverviewCountAPI().then(res => {
                const {info: {all, default: d1, expire}} = res;
                that.all = all;
                that.d1 = d1;
                that.expire = expire;
            });
        }
    },
    mounted() {
        /*let value = this.collectionData;
        const {startDate = null, endDate = null} = value;
        if (startDate && endDate) this.throwLease();
        */
        this.getHomeOverview();
    }
};
</script>

<style lang="scss" scoped>
.retire-situation {
    width: 100%;
    height: 100%;
    padding-left: 10px;

    .retire-item {
        width: calc(50% - 10px);
        height: calc(50% - 10px);
        color: #333;
        font-weight: 600;
        border-radius: 20px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &:nth-of-type(1),
        &:nth-of-type(2) {
            margin-bottom: 20px;
        }

        .label {
            font-size: rem(16px);
            padding: VH(15px) VW(15px);
            text-align: center;
        }

        .value {
            /*position: absolute;
            bottom: VH(15px);
            right: VW(30px);*/
            font-size: rem(19px);

            span {
                font-size: rem(16px);
            }
        }
    }
}

/deep/ .el-radio-button__inner {
    white-space: nowrap;
    background: #FFF;
    border: 1px solid #1684FC;
    font-weight: 600;
    border-left: 0;
    color: #1684FC;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    position: relative;
    cursor: pointer;
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    //padding: 10px VW(29px);
    font-size: 14px;
    border-radius: 0;
}

/deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #FFF !important;
    background-color: #409EFF;
    border-color: #409EFF;
    box-shadow: -1px 0 0 0 #409EFF;
    font-weight: 600;
}

/deep/ .el-radio-button:first-child .el-radio-button__inner {
    border-left: 1px solid #409EFF;
    border-radius: 15px 0 0 15px;
    box-shadow: none !important;
}

/deep/ .el-radio-button:last-child .el-radio-button__inner {
    border-radius: 0 15px 15px 0;
}

/deep/ .el-badge__content.is-fixed {
    position: absolute;
    top: -10px;
    right: -10px;
    transform: translateY(-50%) translateX(100%);
    z-index: 10;
}

/deep/ .el-badge__content {
    background-color: #F56C6C;
    border-radius: 10px;
    color: #FFF;
    display: inline-block;
    font-size: 12px;
    height: 18px;
    line-height: 18px;
    padding: 0 6px;
    text-align: center;
    white-space: nowrap;
    border: 0 solid #FFF;
}
</style>
