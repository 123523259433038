<template>
    <el-upload
        class="upload-avatar"
        :action="uploadInterfaceIp"
        :headers="headersToken"
        :name="name"
        :before-upload="handleBeforeUpload"
        :on-success="handleSuccess"
        :on-error="handleError"
        :accept="accept"
        :show-file-list="false"
    >
        <div class="avatar flex justify-center align-center" v-if="imageUrl">
            <img :src="imageUrl" alt=""/>
            <div class="el-upload-list__item-delete flex justify-center align-center">
                <i class="el-icon-search" @click.stop="handlePictureCardPreview"/>
              <i class="el-icon-bottom" @click.stop="handleDownload"/>
              <i class="el-icon-delete" @click.stop="handleRemove"/>
            </div>
        </div>
        <i v-else class="el-icon-plus avatar-uploader-icon"/>
        <div v-show="showTip" slot="tip" class="el-upload__tip">只能上传{{ accept }}文件</div>
    </el-upload>
</template>

<script>
import {baseConfig} from "@/utils/config";
import {tokenCookies} from "@storage/cookies";
import {MessageError, MessageWarning} from "@custom/message";
import {createFullImageUrl} from "@/components/Upload/index";

export default {
    props: {
        // 文件的唯一值，当前系统为uuid
        uuid: {
            type: String,
            default: "",
        },
        name: {
            type: String,
            default: "file",
        },
        accept: {
            type: String,
            default: ".jpg,.jpeg,.png,.gif",
        },
        // 像素大小[width，height]
        pixel: {
            type: Array,
            default: () => [],
        },
        // 是否提示备注
        showTip: {
            type: Boolean,
            default: true,
        },
      isType: {
        type: String,
        default: '暂无类型',
      },
    },
    name: "upload-avatar",
    data() {
        return {

            limit:'9',
            uploadInterfaceIp: baseConfig.baseURL + "/applet/rent/files/upload",
            headersToken: {
                "X-Access-Token": tokenCookies.get(),
            },
            imageUrl: this.uuid,
        };
    },
    watch: {
        uuid: {
            handler() {
                // 回显图片
                if (!this.uuid) return;
                this.imageUrl = createFullImageUrl(this.uuid);
            },
            immediate: true
        }
    },
    methods: {
      // 2024-9-30-陈守亮-新增下载照片前端方法.
      downloadImage(url, filename) {
        fetch(url)
            .then(response => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.blob(); // 将响应转换为 Blob 对象
            })
            .then(blob => {
              const link = document.createElement('a'); // 创建一个新的链接元素
              link.href = URL.createObjectURL(blob); // 创建指向 Blob 的 URL
              link.download = filename; // 设置下载文件名
              document.body.appendChild(link); // 将链接添加到 DOM
              link.click(); // 模拟点击链接以启动下载
              document.body.removeChild(link); // 移除链接
              URL.revokeObjectURL(link.href); // 释放内存
            })
            .catch(error => {
              console.error('There has been a problem with your fetch operation:', error);
            });
      },
      // 2024-9-30-陈守亮-新增下载照片
        handleDownload(){
          console.log(this.imageUrl)
          this.downloadImage(this.imageUrl, this.isType)
          // window.open(this.imageUrl, '_self')
        },
      // 2024-9-30-陈守亮-新增查看照片
      handlePictureCardPreview() {
        window.open(this.imageUrl, '_blank'); // 在新窗口中打开图片
      },
        handleBeforeUpload(file) {
            return new Promise((resolve, reject) => {
                const {name} = file;

                // 上传文件格式
                const acceptArray = this.accept.split(",");
                const extensionIndex = name.lastIndexOf(".");       // 后缀序列
                const extension = name.substring(extensionIndex);   // 文件后缀
                this.fileName = name.substring(0, extensionIndex);  // 文件名称
                let isFileType = acceptArray.includes(extension);
                if (!isFileType) {
                    MessageWarning(`请上传${this.accept}格式的文件！`);
                    reject(false);
                }
                resolve(isFileType);
            });
        },
        handleRemove(file) {
            // 删除
            this.imageUrl = null;
            this.$emit("on-remove", {file,isType : this.isType});
        },
        handleSuccess(response, file) {
            // 上传成功
            const {resultCode = -1} = response;
            if (resultCode === 1000) {
                this.imageUrl = URL.createObjectURL(file.raw);
                const fileName = this.fileName;
                const info = response.returnObject.info;
                this.$emit("on-success", {fileName, file, info,isType : this.isType});
            } else {
                this.handleError();
            }
        },
        handleError() {
            MessageError("文件上传失败", 1000, () => {
                this.fileList = [];
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.upload-avatar /deep/ .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 88px;
        height: 88px;
        line-height: 88px;
        text-align: center;
    }

    .avatar {
        width: 88px;
        height: 88px;
        position: relative;

        img {
            width: 100%;
            height: 100%;
        }

        .el-upload-list__item-delete {
            width: 100%;
            height: 100%;
            color: #fff;
            font-size: rem(25px);
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, .5);
            transition: opacity .3s;
        }

        &:hover .el-upload-list__item-delete {
            display: flex;
        }
    }
}

</style>
