import request from '@http';

// 2022/06/02 王江毅 首页维修
export function getRepairList(params) {
    return request({
        method: 'get',
        url: '/api/repair/list',
        params,
    });
}

// 2022/12/08 王江毅 管家审核报修工单
export function repairAudit(params) {
    return request({
        method: 'GET',
        url: `/api/repair/audit`,
        params,
    });
}

// 2022/12/08 王江毅 维修人员维修完成提交
export function repairMaintain(data) {
    return request({
        method: 'POST',
        url: `/api/repair/maintain`,
        data,
    });
}

// 2022/12/08 王江毅 管家确认维修完成
export function repairAuditMaintain(params) {
    return request({
        method: 'GET',
        url: `/api/repair/audit/maintian`,
        params,
    });
}

// 2022/12/08 王江毅 维修人员已读工单
export function repairMaintainRead(params) {
    return request({
        method: 'GET',
        url: `/api/repair/maintain/read`,
        params,
    });
}

// 2022/12/08 王江毅 维修工单详情
export function repairInfo(params) {
    return request({
        method: 'GET',
        url: `/api/repair/info`,
        params,
    });
}

// 2023/09/07 张晓瑜 维修搜索类型列表
export function getHousingETypeListApi(params){
    return request({
        method:'GET',
        url: `/api/rent/repair/type/uuid/list`,
        params
    })
}

// 2023/09/21 张晓瑜 维修搜索类型列表
export function getRepairLocationsListApi(){
    return request({
        method:'GET',
        url: `/api/repairs/position/list`,
    })
}

// 2023/10/17 王江毅 查看弹窗“备注”编辑
export function auditCommmentUpdate(params){
    return request({
        method:'GET',
        url: `/api/repair/auditCommment/update`,
        params
    })
}

// 2023/11/21 王江毅 维修工单导出功能
export function repairExportApi(params){
    return request({
        method:'GET',
        url: `/api/repair/list/export`,
        responseType: 'blob',
        params,
    })
}
