<template>
    <!-- 小区动态 -->
    <section class="community-dynamic padding_10_15">
        <!--<r-e-table
                class="bg-white"
                :data="tableData"
                :columns="dynamicTableColumn"
                tooltip-effect="light"
                :height="515"
                :show-pagination="false"
        />-->
        <!--<div class="flex" style="position: fixed;z-index: 100;margin-top: -45px;margin-left: 60px">
             <el-select v-model="formSearch.name" size="mini" clearable filterable allow-create default-first-option
                        placeholder="请选择房源筛选" style="width: 200px;margin-right: 10px">
                  <el-option v-for="item in houseTypes" :key="item.code" :label="item.codeName" :value="item.code"/>
             </el-select>
             <el-select v-model="formSearch.district" size="mini" clearable filterable allow-create default-first-option
                        placeholder="请选择大区筛选" style="width: 200px;margin-right: 10px">
                  <el-option v-for="(item,index) in options" :key="index" :label="item" :value="item"/>
             </el-select>
             <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
        </div>-->
        <!-- 2024/03/25 张晓瑜新增按钮 -->
        <el-radio-group style="color: black;width: 100% !important; margin-bottom:10px" v-model="dynamic" @change="getHomeOverview">
                    <el-radio-button :label="1">
                        <el-badge :value="all">
                            <div style="width: 50px;">
                                套房
                            </div>
                        </el-badge>
                    </el-radio-button>
                    <el-radio-button :label="2">
                        <el-badge :value="expire">
                          <div style="width: 50px;">
                            整租房
                          </div>
                        </el-badge>
                    </el-radio-button>
                    <el-radio-button :label="3">
                        <el-badge :value="d1">
                          <div style="width: 50px;">
                            分租房
                          </div>
                          </el-badge>
                    </el-radio-button>
                </el-radio-group>
        <r-e-table ref="communityTableRef" :dataRequest="getCommunityApi" :columns="dynamicTableColumn"
                   :query="formSearch" :height="465" :notAuto="true" :show-pagination="false"
                   :treeProps="{children: 'children', hasChildren: true}"/>
    </section>
</template>

<script>
import {dynamicTableColumn} from "@/views/home/data";
import {getCommunityApi} from "@/api/home";
import {getAddressList} from "@/api/community-management";

export default {
    name: "community-dynamic",
    data() {
        return {
            tableData: [
                /*{
                    a1: '金色地中海2期',
                    a2: '2',
                    a3: '2',
                    a4: '1',
                    a5: '1',
                    a6: '0',
                    a7: '2',
                },
                {
                    a1: '富航217青年公寓',
                    a2: '201',
                    a3: '185',
                    a4: '55',
                    a5: '40',
                    a6: '10',
                    a7: '185',
                }*/
            ],
            dynamicTableColumn: dynamicTableColumn(this),
            formSearch: {
                name: null,
              splitStatus: null,
            },
            houseTypes: [],
            options: [],
            dynamic:1, // 小区动态
        };
    },
    props: {
        collectionData: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        // 点击套房-整租房-分租房事件
        getHomeOverview(){
          // alert(this.dynamic)
          this.formSearch.splitStatus = this.dynamic
          this.handleSearch()

        },
        //小区动态列表
        getCommunityApi(params) {
            let oldParams = params;
            let collectionData = this.collectionData;
            params = {...oldParams, ...collectionData}
            return getCommunityApi(params);
        },
        //查询
        handleSearch() {
            this.$refs["communityTableRef"].getTableData();
        },
    },
    watch: {
        collectionData(value) {
            this.handleSearch();
        }
    },
    async mounted() {
        //获取查询条件参数
        /*this.houseTypes = await this.$store.dispatch('app/getDictionaryByCodeActions', 600210);
        let list = await this.$store.dispatch('app/getDictionaryByCodeActions', 600220);
        this.houseTypes = this.houseTypes.concat(list);
        let res = await getAddressList();
        this.options = res.list;*/
        let value = this.collectionData;
        const {startDate = null, endDate = null} = value;
        if (startDate && endDate) this.handleSearch();
    }
};
</script>

<style lang="scss" scoped>
/deep/ .r-e-table {
    .el-table .cell {
        //  white-space: nowrap;
        text-align: center;
    }
}

</style>
