<template>
  <r-e-dialog title="新增维修工单" class="layer-contract-detail" :visible.sync="dialogVisible" show-footer top="10vh" width="600px"
              @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
    <el-form size="mini" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="维修位置" prop="repair">
        <el-select v-model="ruleForm.repair" placeholder="请选择维修位置" @change="remoteMethod" style="width: 370px">
          <el-option
              v-for="item in positionList"
              :key="item.uuid"
              :label="item.name"
              :value="item.uuid">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="维修类别" prop="repairType">
        <el-select v-model="ruleForm.repairType" placeholder="请选择维修类别" style="width: 370px">
          <el-option
              v-for="item in positionListson"
              :key="item.uuid"
              :label="item.name"
              :value="item.uuid">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="预约时间" prop="repairdate">
        <el-date-picker
            class="input_item"
            style="width: 370px"
            v-model="ruleForm.repairdate"
            value-format="yyyy-MM-dd"
            size="mini"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="选择小区" prop="repairUuidtype">
<!--        <el-select v-model="ruleForm.type" placeholder="请选择充值类型">-->
<!--          <el-option label="房租充值" value="房租充值"></el-option>-->
<!--          <el-option label="其他费用充值" value="其他费用充值"></el-option>-->
<!--        </el-select>-->
        <el-select v-model="ruleForm.repairUuidtype" filterable placeholder="请选择" @change="remotecommunityListson" style="width: 370px">
          <el-option
              v-for="item in communityList"
              :key="item.uuid"
              :label="item.name"
              :value="item.uuid">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择房间" prop="repairUuid">
        <el-select style="width: 370px"  :loading="loading" v-model="ruleForm.repairUuid" filterable placeholder="请选择" @change="repairUuidListson">
          <el-option
              v-for="item in communityListson"
              :key="item.uuid"
              :label="item.name"
              :value="item.uuid">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="维修备注" prop="repairContent">
        <el-input

            type="textarea"
            :rows="2"
            style="width: 370px"
            placeholder="请输入内容"
            v-model="ruleForm.repairContent">
        </el-input>
      </el-form-item>
      <el-form-item label="联系人" prop="repairPeopleName">
        <el-input style="width: 370px" v-model="ruleForm.repairPeopleName"></el-input>
      </el-form-item>
      <el-form-item label="联系方式" prop="repairPeoplePhone">
        <el-input style="width: 370px" v-model="ruleForm.repairPeoplePhone"></el-input>
      </el-form-item>
      <el-form-item label="现场照片" prop="thumbnail">
        <upload-pictureCard @on-remove="enterpriseRemove" ref="pictureCard" :showTip="false"  isType="授权书" @on-success="enterpriseClick" :limit="9"/>
      </el-form-item>
      <div style="font-size: 12px;color: #B4B3CE;text-align: center;margin-left: -250px">只支持jpg,jpeg,png格式</div>
    </el-form>
  </r-e-dialog>
</template>

<script>
import {czhiputApi} from "@/api/house-resource";
import UploadPictureCard from "@/components/Upload/upload-picture-card.vue";
import {
  communityAllrecoredposition,
  communitylistAPIson,
  communitylistrepairAPI,
  communitylistrepairAPIson, communitylistrepairAPIsonbtnaddadd
} from "@/api/community-management";
import {MessageSuccess} from "@custom/message";
export default {
  name: "",
  components: {UploadPictureCard},
  data(){
    return {
      dialogVisible: false,
      tenantData:{},
      loading: false,
      communityList:[],//维修添加-小区列表
      communityListson:[],
      positionList:[],//维修位置列表
      positionListson:[],
      ruleForm: {
        repair:'',
        repairType:'',
        repairdate:'',
        repairUuidtype:'',
        repairUuid:'',
        repairContent:'',
        thumbnail:[],
        repairPeopleName:'',
        repairPeoplePhone:''
      },
      // .toLocaleString()
      rules: {
        repair:[
          { required: true, message: '维修位置不能为空', trigger: 'blur' }
        ],
        repairType:[
          { required: true, message: '维修类别不能为空', trigger: 'blur' }
        ],
        repairdate:[
          { required: true, message: '维修时间不能为空', trigger: 'blur' }
        ],
        repairUuidtype:[
          { required: true, message: '维修小区不能为空', trigger: 'blur' }
        ],
        repairUuid:[
          { required: true, message: '维修房间不能为空', trigger: 'blur' }
        ],
        repairContent:[
          { required: true, message: '维修备注不能为空', trigger: 'blur' }
        ],
        repairPeopleName:[
          { required: true, message: '联系人不能为空', trigger: 'blur' }
        ],
        repairPeoplePhone:[{ required: true, message: '联系人方式不能为空', trigger: 'blur' }],
        thumbnail:[{ required: true, message: '照片不能为空', trigger: 'blur' }]
      }
    };
  },
  mounted() {
  },
  methods:{
    // 选择完维修位置
    remoteMethod(e){
      this.ruleForm.repairType = ''
      communitylistrepairAPI({positionUuid:e}).then(res => {
        this.positionListson = res.list
        console.log(res)
      })
      console.log(e)
    },
    remotecommunityListson(e){
      this.loading = true
      this.ruleForm.repairUuid = ''
      communitylistAPIson({communityUuid:e}).then(res => {
        this.communityListson = res.list
        this.loading = false
        console.log(res)
      })
      console.log(e)
    },
    repairUuidListson(e){
      communitylistrepairAPIson({apartmentUuid:e}).then(res => {
        console.log(res)
        if(res !== null){
          this.ruleForm.repairPeopleName =  res.name
          this.ruleForm.repairPeoplePhone =  res.tel1
        }
      })
    },
    //删除照片
    enterpriseRemove(row){
      console.log(row)
      this.ruleForm.thumbnail = []
      let {fileList} = row
      fileList.forEach(item => {
        let {response:{returnObject:{info:{uuid}}}} = item
        this.ruleForm.thumbnail.push(uuid)
      })
    },
    // 照片成功
    enterpriseClick(row){
      console.log(row)
      // console.log(this.$refs.pictureCard.fileList)
      // let {info:{uuid}} = row
      // this.ruleForm.thumbnail.push(uuid)
      let {fileList} = row
      fileList.forEach(item => {
        let {response:{returnObject:{info:{uuid}}}} = item
        this.ruleForm.thumbnail.push(uuid)
      })
    },
    clickSubmit(){
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let {repairPeopleName,repairPeoplePhone,repairType,repairdate,thumbnail,repairContent,repairUuid} = this.ruleForm
          thumbnail = thumbnail.join(',')
          //调用接口保存
          let data = {repairPeopleName,repairPeoplePhone,repairType,repairdate,thumbnail,repairContent,repairUuid}
          communitylistrepairAPIsonbtnaddadd(data).then(res => {
            console.log(res.msg)
            this.clickCancel()
            MessageSuccess(res.msg)
          })
        } else {
          this.$message.error('请完善表单相关信息！');
          return false;
        }
      })
    },
    clickCancel(){
      this.ruleForm.thumbnail = []
      // alert(2)
      this.dialogVisible = false
    },

  },

}
</script>

<style scoped>

</style>
