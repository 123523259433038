<template>
    <section class="home">
        <div class="home-header flex justify-between">
            <div class="tools flex">
                <div :class="['btn', { 'active-btn': activeBtn === item.name }]"
                     v-for="item in timeButtons" :key="item.name" @click="handleClickTools(item)">
                    {{ item.label }}
                </div>
                
                <div class="date">
                    <el-date-picker v-model="time" type="daterange" size="small" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期"/>
                    <el-button type="primary" size="small" @click="handleClickTime">查询</el-button>
                    
                    <el-button type="success" size="small" @click="goDataScreen">数据大屏</el-button>
                </div>
            </div>
<!--            <div class="home-tabs">-->
<!--                <el-badge class="item" v-for="item in homeTabOptions" :key="item.name" :value="item.value"-->
<!--                          :hidden="!item.value && item.name === 'overview'" :max="99">-->
<!--                    <div :class="['tab', { 'active-tab': activeTab === item.name }]" @click="clickTab(item)">-->
<!--                        {{ item.label }}-->
<!--                    </div>-->
<!--                </el-badge>-->
<!--            </div>-->
          <div class="home-tabs" style="display: flex">
            <div v-for="item in homeTabOptions" :key="item.name">
              <el-badge v-if="item.value  !== 0" class="item"  :value="item.value"
                        :hidden="!item.value && item.name === 'overview'" :max="99">
                <div :class="['tab', { 'active-tab': activeTab === item.name }]" @click="clickTab(item)">
                  {{ item.label }}
                </div>
              </el-badge>
              <!--                 <el-badge class="item"  :value="item.value"-->
              <!--                           :hidden="!item.value && item.name === 'overview'" :max="99">-->
              <div v-else  style="width: 70px;margin-left: 22px;text-align: center">
                <div   :class="['tab', { 'active-tab': activeTab === item.name }]" @click="clickTab(item)">
                  {{ item.label }}
                </div>
              </div>
              <!--                 </el-badge>-->
            </div>
          </div>
        </div>
        <div class="container-content hidden-scrollbar">
            <component :is="activeTab" :collection-data="collectionData"/>
        </div>
        <r-e-dialog title="版本更新说明" :visible.sync="banben" width="900px" :center="false"
                    @close="clickCancel" @click-cancel="clickCancel">
            <div style="height: 50vh; overflow: auto">
                <el-card v-for="(item, index) in versionList" shadow="never" :key="index"
                         style="margin-bottom: 20px" :style="index + 1 === 1 ? 'background-color: #F1F1F3' : ''">
                    <h2 style="margin-top: 0">{{ item.name }}</h2>
                    <div v-for="(v, index2) in item.common" style="height: 20px; line-height: 20px" :key="index2">
                        {{ v }}
                    </div>
                </el-card>
                <el-link type="danger" :underline="false" v-if="formSearch.page < formSearch.totalPage"
                         @click="nextPage">
                    查看更多
                </el-link>
            </div>
            <div style="text-align: right">
                <el-checkbox v-model="checked">不再提示</el-checkbox>
            </div>
        </r-e-dialog>
    </section>
</template>

<script>
import {timeButtons, homeTabOptions} from "@/views/home/data";
import {overview, checkOut, complaint, repair, exchangeHouses, renewalToExamine} from "@/views/home/components";
import {timeFormat} from "@custom/index";
import {getFeedbackList, homeCount, auditSize, repairSizeApi} from "@/api/home";
import {getVersionListApi} from "@/api/version-list";

export default {
    name: "index",
    data() {
        return {
            timeButtons,
            homeTabOptions: homeTabOptions,
            activeTab: "overview",
            activeBtn: "day",
            time: [],
            collectionData: {
                startDate: null,
                endDate: null,
            },
            banben: false,
            versionList: [],
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            },
            formSearch: {
                page: 1,
                pageSize: 20,
                totalNum: 1,
                totalPage: 1,
            },
            checked: false,
        };
    },
    components: {
        overview,
        checkOut,
        complaint,
        repair,
        exchangeHouses,
        renewalToExamine,
    },
    methods: {
        // 首页搜索条件 选择事件
        handleClickTools({name}) {
            this.activeBtn = name;
            let startDate = "", endDate = "";
            if (name === "day") {
                startDate = timeFormat(new Date());
                endDate = timeFormat(new Date());
            }
            if (name === "month") {
                let now = new Date(); //当前日期
                let nowMonth = now.getMonth(); //当前月
                let nowYear = now.getFullYear(); //当前年
                //本月的开始时间
                let monthStartDate = new Date(nowYear, nowMonth, 1);
                //本月的结束时间
                let monthEndDate = new Date(nowYear, nowMonth + 1, 0);
                startDate = timeFormat(new Date(monthStartDate));
                endDate = timeFormat(new Date(monthEndDate));
            }
            this.time = [startDate, endDate];
            this.collectionData = {startDate, endDate};
        },
        
        // 搜索按钮点击事件
        handleClickTime() {
            if (this.time !== null && this.time !== "") {
                let [startDate, endDate] = this.time;
                startDate = timeFormat(new Date(startDate));
                endDate = timeFormat(new Date(endDate));
                this.collectionData = {startDate, endDate};
            } else {
                let startDate = "", endDate = "";
                let name = this.activeBtn;
                if (name === "day") {
                    startDate = timeFormat(new Date());
                    endDate = timeFormat(new Date());
                }
                if (name === "month") {
                    let now = new Date(); //当前日期
                    let nowMonth = now.getMonth(); //当前月
                    let nowYear = now.getFullYear(); //当前年
                    //本月的开始时间
                    let monthStartDate = new Date(nowYear, nowMonth, 1);
                    //本月的结束时间
                    let monthEndDate = new Date(nowYear, nowMonth + 1, 0);
                    startDate = timeFormat(new Date(monthStartDate));
                    endDate = timeFormat(new Date(monthEndDate));
                }
                this.collectionData = {startDate, endDate};
            }
        },
        
        //获取投诉数量
        getFeedbackList() {
            getFeedbackList({state: 600401}).then((res) => {
                let {page: {totalNum},} = res;
                for (let item of this.homeTabOptions) {
                    let {label} = item;
                    if (label === "投诉") item.value = totalNum;
                }
            });
        },
        // 获取待维修数量
        getRepairList() {
            repairSizeApi().then((res) => {
                let {info} = res;
                for (let item of this.homeTabOptions) {
                    let {label} = item;
                    if (label === "维修") item.value = info;
                }
            });
        },
        // 获取预约、换房、退房数量
        homeCount() {
            homeCount().then((res) => {
                let {info: {subscribe, change, exit},} = res;
                for (let item of this.homeTabOptions) {
                    let {label} = item;
                    if (label === "预约") item.value = subscribe;
                    if (label === "换房") item.value = change;
                    if (label === "退房") item.value = exit;
                }
            });
        },
        //切换界面
        clickTab(data) {
            if (data.name === "appointment") {
                //点击预约是跳转预约列表
                this.$router.push({name: "booking-management"});
                return;
            }
            this.activeTab = data.name;
        },
        // 获取续租数量
        auditSize() {
            auditSize().then((res) => {
                let {info: {size},} = res;
                for (let item of this.homeTabOptions) {
                    let {label} = item;
                    if (label === "续租") item.value = size;
                }
            });
        },
        // 获取版本列表
        getVersionListApi(params) {
            let that = this;
            let loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            getVersionListApi(params).then((res) => {
                let {list, page} = res;
                for (let item of list) {
                    item.common = item.common ? item.common.split("\n") : [];
                }
                that.versionList = that.versionList.concat(list);
                that.formSearch = {...page};
            }).finally(() => loading.close());
        },
        
        //关闭版本列表弹框
        clickCancel() {
            this.banben = false;
            localStorage.setItem("bbxs1", this.checked ? 1 : 0);
        },
        // 版本列表下一页
        nextPage() {
            this.formSearch.page = this.formSearch.page + 1;
            this.getVersionListApi(this.formSearch);
        },
        
        //跳转数据大屏
        goDataScreen() {
            const newWindows = this.$router.resolve({path: "/data-screen"});
            window.open(newWindows.href, "_blank");
        },
        
    },
    mounted() {
        let startDate = "", endDate = "";
        startDate = timeFormat(new Date());
        endDate = timeFormat(new Date());
        this.collectionData = {startDate, endDate};
        this.time = [startDate, endDate];
        //初始化加载数据
        this.getFeedbackList();
        this.getRepairList();
        this.homeCount();
        this.auditSize();
        let bbxs = Number(localStorage.getItem("bbxs1"));
        if (!bbxs) {
            this.getVersionListApi(this.formSearch);
            this.banben = true;
        }
    },
};
</script>

<style lang="scss" scoped>
.home {
    .home-header {
        width: 75%;
        position: fixed;
        top: VH(40px);
        
        .tools {
            margin-left: VW(10px);
            
            .btn {
                font-size: rem(12px);
                cursor: pointer;
                padding: VH(7px) VW(15px);
                margin-left: VW(10px);
                
                &.active-btn {
                    color: #fff;
                    border-radius: 4px;
                    background: linear-gradient(80deg, #a8d4ff 0%, #2386f6 100%);
                    box-shadow: 0 6px 12px rgba(0, 45, 170, 0.16);
                }
            }
            
            .el-date-editor {
                margin: 0 VW(10px);
            }
        }
        
        .tab {
            cursor: pointer;
            padding: VH(7px) VW(15px);
            
            &.active-tab {
                color: #fff;
                border-radius: 4px;
                background: linear-gradient(80deg, #a8d4ff 0%, #2386f6 100%);
                box-shadow: 0 6px 12px rgba(0, 45, 170, 0.16);
            }
        }
    }
    
    .item {
        margin: 0 15px;
    }
}
</style>
