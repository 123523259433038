import request from '@http';
// 获取版本列表
export function getVersionListApi(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/issue/all',
        params,
    });
}

// 新增版本信息
export function addVersion(data) {
    return request({
        method: 'PUT',
        url: '/api/gzf/issue/publish',
        data,
    });
}

// 修改版本信息
export function updateVersion(data) {
    return request({
        method: 'PUT',
        url: '/api/gzf/issue/update',
        data,
    });
}
// 删除版本信息
export function getDelIssueApi(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/issue/del',
        params,
    });
}
