/**
 *
 * 简述：时间格式
 *
 * params:
 *   date: 时间 eg：new Date()
 *   fmt：格式  eg：yyyy-MM-dd HH:mm:ss  yyyy-MM-dd
 *
 * 使用
 *   eg: timeFormat(new Date(), "yyyy-MM-dd HH:mm:ss")
 *
 * */
export function timeFormat(date, fmt = "yyyy-MM-dd") {
    // console.log(date)
    let o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "H+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        "S": date.getMilliseconds(), //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (let k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

/**
 * 2022/03/22 王江毅添加 格式化数字方法
 * 简述：数字格式(金额)
 *
 * 参数说明：
 * number：要格式化的数字
 * decimals：保留几位小数
 * dec_point：小数点符号
 * thousands_sep：千分位符号
 *
 *
 * */

export function numberFormat(number = 0, decimals = 2, dec_point = ".", thousands_sep = ",") {
    number = parseFloat(number);
    //金额转换 分->元 保留2位小数 并每隔3位用逗号分开 1,234.56
    let str = (number).toFixed(decimals) + '';
    let intSum = str.substring(0, str.indexOf(dec_point)).replace(/\B(?=(?:\d{3})+$)/g, thousands_sep);//取到整数部分
    let dot = str.substring(str.length, str.indexOf("."))//取到小数部分搜索
    return intSum + dot;
}
export function numberFormatFour(number = 0, decimals = 4, dec_point = ".", thousands_sep = ",") {
    number = parseFloat(number);
    //金额转换 分->元 保留4位小数 并每隔3位用逗号分开 1,234.56
    let str = (number).toFixed(decimals) + '';
    let intSum = str.substring(0, str.indexOf(dec_point)).replace(/\B(?=(?:\d{3})+$)/g, thousands_sep);//取到整数部分
    let dot = str.substring(str.length, str.indexOf("."))//取到小数部分搜索
    return intSum + dot;
}
// 2022/03/22 王江毅添加 分转元
export function ParseFloatFormat(number = 0) {
    number = parseFloat(number);
    return parseFloat((number / 100).toFixed(2));
}
export function ParseFloatFormatFour(number = 0) {
    number = parseFloat(number);
    return parseFloat((number / 100));
}
// 2022/03/22 王江毅添加 元转分
export function ParseIntFormat(number = 0) {
    return parseInt((parseFloat(number) * 100).toFixed(0));
}

/**
 * 2023-8-16 王江毅
 * 验证字符串是否能转成数字
 * @param value 字符串
 * @returns {boolean} 是否能转换成数字
 */
export function isNumeric(value) {
    if (typeof value !== 'string') {
        return false;
    }

    // 如果字符串为空，则不是数字
    if (value.trim() === '') {
        return false;
    }

    if (value.charAt(0) === '.' || value.charAt(value.length - 1) === '.') {
        return false;
    }
    // 使用 Number.isNaN() 方法判断是否为 NaN
    return !Number.isNaN(Number(value));
}

/**
 * 2023/08/24 王江毅
 * 获取两个日期间相隔月数
 * @param startDate
 * @param endDate
 */
export function getMonthDiff(startDate, endDate) {
    let start = (new Date(startDate)).getTime();
    let end = (new Date(endDate)).getTime();
    let number = (end - start) / 1000;
    console.log("number", Math.floor(number / 3600 / 24 / 30));
    return parseInt((number / 3600 / 24 / 30) + '');
}