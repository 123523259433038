<template>
    <section class="slippage-tenant">
        <div class="flex justify-between align-center">
            <div class="title" style="margin-left: 20px;">逾期租户名单</div>
<!--          2023-11-23-陈守亮-新增区域搜索-->
            <div style="margin-right: 30px">
              <!-- <el-select  v-model="formSearch.district" size="small" clearable filterable allow-create
                         default-first-option  blur placeholder="请选择区域" style="width: 120px;">
                <el-option v-for="(item,index) in options" :key="index" :label="item"
                           :value="item"/>
              </el-select> -->
              <el-select v-model="formSearch.apartmentCode" size="small" clearable filterable allow-create
                         default-first-option placeholder="请选择住房类型" style="width: 140px;">
                <el-option label="全部" :value="0"/>
                <el-option label="保障性租赁住房" :value="600210"/>
                <el-option label="长租公寓" :value="600220"/>
              </el-select>
              <el-select size="small" v-model="formSearch.apartmentCodeson" placeholder="请选择"
                         style="width: 120px;">
                <el-option label="全部" :value="0"/>
                <el-option v-for="(item,index) in houseTypes" :key="index" :label="item.codeName"
                           :value="item.code"/>
              </el-select>
<!--              <el-select @change="changeSaixuan" v-model="formSearch.leasorType" size="small" clearable filterable allow-create-->
<!--                         default-first-option placeholder="请选择" style="width: 90px;">-->
<!--                <el-option label="全部" :value="0"/>-->
<!--                <el-option  label="个人" value="个人"/>-->
<!--                <el-option  label="法人" value="法人"/>-->
<!--              </el-select>-->
<!--              <el-select v-model="value" placeholder="请选择">-->
<!--                <el-option-->
<!--                    v-for="item in optionss"-->
<!--                    :key="item.value"-->
<!--                    :label="item.label"-->
<!--                    :value="item.value">-->
<!--                </el-option>-->
<!--              </el-select>-->
              <el-button style="margin-left: 12px;" size="mini" type="primary" @click="changeSaixuan">查 询</el-button>
                <el-button style="margin-left: 12px;" size="mini" type="primary" @click="handleReminder">催 收</el-button>
            </div>
        </div>
        <div
            style="margin: 10px 0;padding-bottom: 50px;background-color: white;margin-left: 25px;" >
            <r-e-table  class="bg-white" ref="orderTableRef" :data="getTableDatalist" :columns="tableColumn"
                       :query="formSearch" :height="425" :showPagination="false"
                       @selection-change="handleSelectionChange">
                <template slot="empty">
                    <el-empty/>
                </template>
            </r-e-table>
          <el-pagination
              style="margin-left: 22px"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage4"
              :page-sizes="[10, 20, 40, 80]"
              :page-size="100"
              layout="total, sizes, prev, pager, next,jumper"
              :total="page.totalNum">
          </el-pagination>
        </div>
    </section>
</template>

<script>
import {slippageTableColumn} from "@/views/home/data";
import {getHomeLateLeasorAPI, batchReminderAPI} from '@/api/home';
import {MessageSuccess, MessageInfo} from "@custom/message";
import {getAddressList} from "@/api/community-management";
import el from "element-ui/src/locale/lang/el";

export default {
    name: "slippage-tenant",
    data() {
        return {
          optionss: [{
            value: '选项1',
            label: '黄金糕'
          }, {
            value: '选项2',
            label: '双皮奶'
          }, {
            value: '选项3',
            label: '蚵仔煎'
          }, {
            value: '选项4',
            label: '龙须面'
          }, {
            value: '选项5',
            label: '北京烤鸭'
          }],
          value: '',
          houseTypes:[],
            tableColumn: slippageTableColumn(this),
            formSearch: {
                keyword: null,
              leasorType:null,
              apartmentCodeson:null,
              apartmentType:null,
              district:""
            },
            uuids: null,
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
          options:[],
          getTableDatalist:[],
          currentPage4: 1,
          page:{
            totalNum:'',
            page:'1',
            pageSize:'10',
            totalPage: '10'
          }
        };
    },
    components: {
    },
     created() {
        this.getTableData()
      },
  watch:{
    async 'formSearch.apartmentCode'(value) {
      if (value !== 0) {
        this.formSearch.apartmentCodeson = null;
        this.houseTypes = await this.$store.dispatch('app/getDictionaryByCodeActions', value);
      } else {
        this.formSearch.apartmentCodeson = null;
        this.houseTypes = [];
      }
    },
  },
  async mounted() {
      let res = await getAddressList();
      this.options = res.list;
    this.options.unshift('全部')
    },
    methods: {
      handleSizeChange(e){
        console.log(e)
        this.page.pageSize = e
        this.getTableData()
      },
      handleCurrentChange(e){
        console.log(e)
        this.page.page = e
        this.getTableData()
      },
        // 数据请求方法
        getTableData(params) {
          console.log('我被请求了')
          // console.log(params)
          console.log(getHomeLateLeasorAPI({district: this.formSearch.district}))
          getHomeLateLeasorAPI({...this.formSearch,...this.page}).then(res => {
            


            console.log(res)
            this.page =res.page
            // projectList.value = list.sort((a, b) => {
            //   return b.usedVolume - a.usedVolume;
            // });
            this.getTableDatalist = res.list
            this.getTableDatalist = this.getTableDatalist.sort((a, b) => {
              return b.lateDays - a.lateDays;
            });
          })
        },
        handleSelectionChange(row) {
            if (row.length > 0) {
                this.uuids = row.map(({contractNum}) => contractNum).join(';');
            } else {
                this.uuids = null;
            }
        },
        handleReminder() {
            const that = this;
            const uuids = this.uuids;
            if (uuids) {
                this.$confirm('此操作将发送催收短信, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});

                    batchReminderAPI({uuids}).then(res => {
                        MessageSuccess('催收成功');
                    }).finally(() => loading.close());
                }).catch(() => {
                });
            } else {
                MessageInfo("请选择催款租户");
            }
        },
      // 赛选小区
      changeSaixuan(){
         this.getTableData()
        console.log(this.formSearch)

      }
    }
}
</script>

<style lang="scss" scoped>
.slippage-tenant {
    width: 49%;
    .title {
        height: VH(55px);
        line-height: VH(55px);
        color: #666;
        padding-left: VW(10px);
        position: relative;

        &::before {
            width: 5px;
            height: 40%;
            background-color: #5C84FB;
            content: '';
            position: absolute;
            left: 0;
            top: 30%;
        }
    }
}
</style>
