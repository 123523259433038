// 关于项目中的金额单位转换
export function conversionAmount(money = 0) {
    if (!money && money != 0) return "-";
    return money / 100 + "元";
}
// 分转化为元 - 正则解决精度

export const regFenToYuan = (fen) =>{
    var num = fen;
    num=fen*0.01;
    num+='';
    var reg = num.indexOf('.') >-1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g;
    num=num.replace(reg,'$1');
    num = toDecimal2(num)
    return num
};

// 强制保留2位小数，如：2，会在2后面补上00.即2.00

export const toDecimal2 = (x) => {
    let f = parseFloat(x);
    if (isNaN(f)) {
        return false;
    }
     f = Math.round(x * 100) / 100;
    var s = f.toString();
    var rs = s.indexOf('.');
    if (rs < 0) {
        rs = s.length;
        s += '.';
    }
    while (s.length <= rs + 2) {
        s += '0';
    }
    return s;
}

/**
 // 陈守亮-2024-3-21-富航判断是否成年函数
 2  * 根据身份证号得到姓别和精确计算年龄
 3  */
export function analyzeIDCard(IDCard){
         //获取用户身份证号码
         var userCard = IDCard;
         //如果身份证号码为undefind则返回空
         if(!userCard){
                 return false;
             }
         //获取出生年月日
         var yearBirth = userCard.substring(6,10);
         var monthBirth = userCard.substring(10,12);
         var dayBirth = userCard.substring(12,14);
         //获取当前年月日并计算年龄
         var myDate = new Date();
         var monthNow = myDate.getMonth() + 1;
         var dayNow = myDate.getDate();
         var age = myDate.getFullYear() - yearBirth;
         if(monthNow < monthBirth || (monthNow == monthBirth && dayNow < dayBirth)){
                 age--;
             }
         if (age >= 18) { return true; }
         return false;
   }
// 2024/04/16 张晓瑜路由跳转并打开网页
export function openNewWindow(router, path) {
    const newWindow = router.resolve({ path: path });
    window.open(newWindow.href, "_blank");
}